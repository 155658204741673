import React, { useCallback, useEffect, useState } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Modal from '@components/common/modal/Modal'
import moment from 'moment'
import Loader from '@components/common/Loader'
import DividerLine from '@components/common/DividerLine'
import { FlexContainer, YSpacing } from '@components/common'
import {
  ErrorLink,
  OrderableConfirmationParamaters,
  Orderable,
  SuccessLink,
} from '@types'

type OrderableRequestResult = Orderable | null

interface Props {
  orderable: string // Proposal or Order - used for display
  orderableType: string // proposals or sales - used for redirect
  params: OrderableConfirmationParamaters[]
  hqLocaleMap: Record<number, string>
  coordinator: (
    hqLocaleMap: Record<number, string>,
    params: OrderableConfirmationParamaters[],
    callbackFn: (
      result: Orderable | null,
      index: number,
      error?: string,
    ) => void,
  ) => Orderable
  errorLinks: ErrorLink[]
  successLinks: SuccessLink[]
  uri: string
  onClose: () => void
}

const OrderableConfirmationModal = ({
  orderable,
  orderableType,
  params,
  hqLocaleMap,
  coordinator,
  onClose,
  uri,
  errorLinks,
  successLinks,
}: Props) => {
  const [results, setResults] = useState<(OrderableRequestResult | string)[]>(
    [],
  )
  const [isLoading, setIsLoading] = useState<boolean[]>([])

  const handleRequestFinish = useCallback(
    (index: number, result: OrderableRequestResult, error?: string) => {
      setResults((prev) => {
        const newResults = [...prev]
        newResults[index] = error ? error : result

        return newResults
      })
      setIsLoading((prev) => {
        const newLoading = [...prev]
        newLoading[index] = false

        return newLoading
      })
    },
    [],
  )

  useEffect(() => {
    setIsLoading(Array(params.length).fill(true))
    coordinator(
      hqLocaleMap,
      params,
      (result: OrderableRequestResult, index: number, error?: string) => {
        handleRequestFinish(index, result, error)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, coordinator])

  const handleOnClick = (record: OrderableRequestResult) => {
    if (orderable && orderableType && record) {
      window.open(
        `${uri}/${orderableType.toLowerCase()}/?${orderable.toLowerCase()}_id=${
          record.id
        }`,
      )
    }
  }

  const renderErrors = () => {
    const errors = params.filter(
      (_, index) => typeof results[index] === 'string',
    )
    const allFailed = errors.length === params.length
    const allFinished =
      results.every((result) => result !== null) &&
      results.length === params.length
    if (!allFinished) {
      return <></>
    }

    if (errors.length > 0) {
      return (
        <FlexContainer flexDirection="column" alignItems="center">
          <h1 className="text-red-500 text-xl mb-4">
            &#9940;{' '}
            {allFailed
              ? `ERROR: Bulk ${orderable}s NOT created!`
              : `WARNING: ${orderable}s Not Created`}{' '}
            &#128581;
          </h1>
          {params.map((p, index) => {
            const message = results[index]
            if (typeof message === 'string') {
              return (
                <React.Fragment key={`error-${index}`}>
                  <FlexContainer
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <p className="font-semibold">
                      {moment(p.date).format('dddd, MMMM Do')}
                    </p>
                    <FaLongArrowAltRight size={30} />
                    <p className="text-red-500">{message}</p>{' '}
                  </FlexContainer>
                  <YSpacing height="10px" />
                </React.Fragment>
              )
            }
          })}
          <DividerLine margin="20px 0" />
        </FlexContainer>
      )
    }
  }

  const renderSuccesses = () => {
    const successes = params.filter(
      (_, index) => typeof results[index] !== 'string',
    )
    const finishedLoading = results.filter((r) => r).length === params.length
    const allSucceeded = successes.length === params.length

    return (
      <FlexContainer flexDirection="column" alignItems="center">
        {finishedLoading && successes.length > 0 && (
          <h1 className="text-green-500 text-xl mb-4">
            {' '}
            {allSucceeded
              ? `Bulk ${orderable}s Created!`
              : `The following ${orderable}s were created`}
            &#127881;
          </h1>
        )}
        {params.map((p, index) => {
          const result = results[index]
          if (typeof result !== 'string') {
            const message =
              result && result.orderNumber ? result.orderNumber : ''

            return (
              <React.Fragment key={`success-${index}`}>
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p className="font-semibold" data-testId="orderable">
                    {moment(p.date).format('dddd, MMMM Do')}
                  </p>
                  <FaLongArrowAltRight size={30} />
                  {isLoading[index] ? (
                    <Loader />
                  ) : (
                    <p
                      onClick={result ? () => handleOnClick(result) : undefined}
                      className="text-green-500 underline cursor-pointer"
                      data-testId="result"
                    >
                      {message}
                    </p>
                  )}
                </FlexContainer>
                <YSpacing height="10px" />
              </React.Fragment>
            )
          }
        })}
      </FlexContainer>
    )
  }

  const renderLinks = () => {
    const allFinished =
      results.every((result) => result !== null) &&
      results.length === params.length
    const hasErrors = results.some((result) => typeof result === 'string')
    if (allFinished && !hasErrors) {
      if (successLinks) {
        return (
          <>
            {successLinks.map((link, index) => (
              <a
                key={index}
                href={link.html}
                className="text-blue-600 underline cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.message}
              </a>
            ))}
          </>
        )
      }
    } else if (hasErrors) {
      if (errorLinks) {
        return (
          <FlexContainer alignItems="flex-start" justifyContent="space-evenly">
            {errorLinks.map((link, index) => (
              <p
                key={index}
                className="underline cursor-pointer"
                onClick={link.clickHandler}
              >
                {link.message}
              </p>
            ))}
          </FlexContainer>
        )
      }
    }
  }

  return (
    <Modal hideModal={onClose} title={`Create ${orderable}s`}>
      {renderErrors()}
      {renderSuccesses()}
      {renderLinks()}
    </Modal>
  )
}

export default OrderableConfirmationModal
