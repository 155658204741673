import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import Moment from 'moment-timezone'

import Modal from '@components/common/modal/Modal'
import { FlexContainer, YSpacing, DividerLine } from '@components/common'
import { Label } from '@res/styledComponents/index'
import {
  MarketDropdown,
  AutocompleteInput,
  TextInput,
  Dropdown,
  MultiSelectDropDown,
  Checkbox,
  Button,
  DateTextInput,
  Input,
  CurrencyInput,
} from '@components/common/form'

import {
  ApiDinerProfile,
  DinerProfile,
  IndexHeadquarter,
  Account,
  Contact,
  Address,
} from '@types'

import { AVERAGE_PRICE_PER_PERSON_TYPES } from '@constants'

interface EditDinerProfileModalProps {
  user: { name: string; locale: string }
  account: Account
  dinerProfile: DinerProfile
  saveDinerProfile: (profile: DinerProfile) => Promise<DinerProfile | null>
  deleteDinerProfile: (profile: DinerProfile) => Promise<boolean>
  closeModal: () => void
  loadHeadquarters: () => Promise<IndexHeadquarter[]>
  loadDinerProfiles: (
    params: Record<string, string | number | null>,
  ) => Promise<ApiDinerProfile[]>
  loadAccountDinerProfiles: (
    accountId: string,
    contacts: Contact[],
    addresses: Address[],
  ) => Promise<DinerProfile[]>
  loadOrderSettings: () => Promise<{
    allOrderTypes: { value: string; text: string }[]
  }>
  loadConceptsSettings: () => Promise<{ allCuisines: string[] }>
  loadMenuItemSettings: () => Promise<{ allDietaryPreferences: string[] }>
}

type Option = {
  value: string
  label: string
}

const EditDinerProfileModal: React.FC<EditDinerProfileModalProps> = ({
  user,
  account,
  dinerProfile,
  saveDinerProfile,
  deleteDinerProfile,
  closeModal,
  loadHeadquarters,
  loadDinerProfiles,
  loadAccountDinerProfiles,
  loadOrderSettings,
  loadConceptsSettings,
  loadMenuItemSettings,
}) => {
  const [profile, setProfile] = useState({ ...dinerProfile })
  const [hqId, setHqId] = useState<number | null>(null)
  const [headquarters, setHeadquarters] = useState<IndexHeadquarter[]>([])

  const [allOrderTypes, setAllOrderTypes] = useState<Option[]>([])
  const [allCuisines, setAllCuisines] = useState<Option[]>([])
  const [allDietaryPreferences, setAllDietaryPreferences] = useState<Option[]>(
    [],
  )

  useEffect(() => {
    const loadHqs = async () => {
      const hqData = await loadHeadquarters()
      setHeadquarters(hqData)
    }
    loadHqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Promise.all([
      loadOrderSettings(),
      loadConceptsSettings(),
      loadMenuItemSettings(),
    ]).then(([orderSettings, conceptSettings, menuItemSettings]) => {
      setAllOrderTypes(
        orderSettings.allOrderTypes.map((ot) => ({
          value: ot.value,
          label: ot.text,
        })),
      )
      setAllCuisines(
        conceptSettings.allCuisines.map((c) => ({ value: c, label: c })),
      )
      setAllDietaryPreferences(
        menuItemSettings.allDietaryPreferences.map((dp) => {
          const cleaned = dp.replace('contains ', '')

          return {
            value: cleaned,
            label: cleaned,
          }
        }),
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadProfiles = useCallback(
    async (search: string) => {
      if (!hqId) {
        return []
      }

      return loadDinerProfiles({ hqId, search })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hqId],
  )

  const renderProfileOption = (profile: ApiDinerProfile) => {
    const marketString =
      headquarters.find((hq) => hq.id === profile.hqId)?.prefix || 'N/A'

    return (
      <div
        key={profile.id}
        className="cursor-pointer hover:bg-gray-200 p-2"
        onClick={() => onSelectProfile(profile)}
      >
        <span className="font-semibold">{profile.name}</span>{' '}
        {`[Market: ${marketString}; Created ${Moment(profile.createdAt).format(
          'MM/DD/YYYY',
        )}]`}
      </div>
    )
  }

  const onSelectProfile = (copyProfile: ApiDinerProfile) => {
    // in case the dp is not associated with this account
    const { contactId, addressId, ...rest } = copyProfile
    const contact = account.contacts.find((c) => c.id === contactId)
    const address = account.addresses.find((a) => a.id === addressId)

    setProfile({
      ...rest,
      contact: contact || ({} as Contact),
      address: address || ({} as Address),
      id: undefined,
      name: `${copyProfile.name} (Copy)`,
      isDefault: false,
    })
  }

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value
    setProfile({ ...profile, name })
  }

  const onChangeMealType = (e: ChangeEvent<HTMLSelectElement>) => {
    const mealType = e.target.value
    setProfile({ ...profile, mealType })
  }

  const onChangeEventTime = (value: Moment.Moment) => {
    const { locale } = user
    const m = Moment(value).tz(locale)
    const pickupTime = m.hour() * 100 + m.minute()
    setProfile({ ...profile, eventTime: pickupTime })
  }

  const pEventTime = (time: number) =>
    Moment(time)
      .tz(user.locale)
      .set({
        h: time / 100,
        m: time % 100,
        s: 0,
      })

  const onChangeHeadcount = (e: ChangeEvent<HTMLInputElement>) => {
    const headcount = parseInt(e.target.value)
    setProfile({ ...profile, headcount })
  }

  const onChangeAddress = (e: ChangeEvent<HTMLSelectElement>) => {
    const address = account.addresses.find(
      (address) => address.id === e.target.value,
    )

    if (address) {
      setProfile({ ...profile, address })
    }
  }

  const addressLabel = (address: {
    line1: string
    city: string
    state: string
    zip: string
  }) => {
    return `${address.line1}, ${address.city}, ${address.state}, ${address.zip}`
  }

  const onChangeContact = (e: ChangeEvent<HTMLSelectElement>) => {
    const contact = account.contacts.find(
      (contact) => contact.id === e.target.value,
    )

    if (contact) {
      setProfile({ ...profile, contact })
    }
  }

  const onSaveProfile = async () => {
    const updatedProfile = await saveDinerProfile(profile)
    if (updatedProfile) {
      await loadAccountDinerProfiles(
        updatedProfile.accountId,
        account.contacts,
        account.addresses,
      )
      closeModal()
    }
  }

  const onDeleteDinerProfile = async () => {
    await deleteDinerProfile(profile)
    await loadAccountDinerProfiles(
      profile.accountId,
      account.contacts,
      account.addresses,
    )
    closeModal()
  }

  return (
    <Modal
      title={
        dinerProfile.id
          ? `Edit Diner Profile: ${dinerProfile.name}`
          : 'Add New Diner Profile'
      }
      hideModal={closeModal}
      color="#001940"
      width="900px"
    >
      <Label fontSize="16px">Copy Existing Diner Profile</Label>
      <p className="mt-5 mb-3" style={{ fontStyle: 'italic' }}>
        *Select market in order to search diner profiles
      </p>
      <FlexContainer flexDirection="row" width="100%">
        <div style={{ width: '30%' }}>
          <MarketDropdown
            options={headquarters}
            onSelectHq={(id) => setHqId(id)}
            onDeselectHq={() => setHqId(null)}
            selectedOptions={hqId ? [hqId] : []}
          />
        </div>
        <div style={{ width: '33%', marginLeft: '10%' }}>
          <AutocompleteInput
            disabled={!hqId}
            placeholder="Select market, then search by name"
            loaderFunction={({ search }: { search: string }) =>
              loadProfiles(search)
            }
            optionRenderFunction={renderProfileOption}
          />
        </div>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer flexDirection="row" alignItems="center" width="100%">
        <div style={{ width: '45%' }}>
          <Label>*Diner Profile Name</Label>
          <TextInput
            testId="diner-profile-name"
            label="Diner Profile Name"
            value={profile.name}
            onChange={onChangeName}
          />
        </div>
        <p style={{ marginLeft: '10%', fontStyle: 'italic' }}>
          Create a distinct diner profile name to capture this group of diners.
          Diner profiles can be used across multiple clients, locations, and
          contacts.
        </p>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <div>
          <Label>*Meal Type</Label>
          <Dropdown
            value={profile.mealType || ''}
            onChange={onChangeMealType}
            testId="diner-profile-meal-type"
          >
            <option value="">Select meal type...</option>
            {allOrderTypes.map((orderType) => (
              <option key={orderType.value} value={orderType.value}>
                {orderType.label}
              </option>
            ))}
          </Dropdown>
        </div>
        <div>
          <Label>*Default Event Time</Label>
          <DateTextInput
            testId="diner-profile-event-time"
            date={pEventTime(profile.eventTime)}
            dateFormat="h:mm a"
            onChange={onChangeEventTime}
            timeZone={user.locale}
          />
        </div>
        <div>
          <Label>*Default Headcount</Label>
          <Input
            testId="diner-profile-headcount"
            type="number"
            value={profile.headcount?.toString() || ''}
            onChange={onChangeHeadcount}
          />
        </div>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Label>*Default Delivery Address</Label>
        <Dropdown
          testId="diner-profile-address"
          width="50%"
          value={profile.address?.id || ''}
          onChange={onChangeAddress}
        >
          <option value="">Select an address...</option>
          {account.addresses.map((address) => (
            <option key={address.id} value={address.id}>
              {addressLabel(address)}
            </option>
          ))}
        </Dropdown>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Label>*Default Client Contact</Label>
        <Dropdown
          testId="diner-profile-contact"
          width="50%"
          value={profile.contact?.id || ''}
          onChange={onChangeContact}
        >
          <option value="">Select a contact...</option>
          {account.contacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.name}
            </option>
          ))}
        </Dropdown>
      </FlexContainer>
      <DividerLine margin="20px 0" />
      <p style={{ color: '#E05267' }} className="font-bold">
        For Smart Recommendations ✨
      </p>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        width="67%"
        justifyContent="space-between"
      >
        <div>
          <Label>Budget Per Employee</Label>
          <CurrencyInput
            width="100%"
            delay={10} // ?????????????????????????? without this delay, copying a profile will cause this onChange to fire and leave us with initial state + copied value
            value={profile.budgetPerEmployee}
            onChange={(value: number) => {
              setProfile({
                ...profile,
                budgetPerEmployee: value,
              })
            }}
          />
        </div>
        <div>
          <Label>Budget Per Employee Type</Label>
          <Dropdown
            width="100%"
            value={profile.budgetPerEmployeeType || ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setProfile({ ...profile, budgetPerEmployeeType: e.target.value })
            }}
          >
            <option key={-1} value="">
              Select
            </option>
            {AVERAGE_PRICE_PER_PERSON_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Dropdown>
        </div>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer width="100%" justifyContent="space-between">
        <FlexContainer flexDirection="column" width="45%">
          <Label>Dietary Preferences & Known Allergens</Label>
          <YSpacing height="10px" />
          <Checkbox
            label="Must Include"
            checked={profile.mustIncludeDietPrefsActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustIncludeDietPrefsActive: !profile.mustIncludeDietPrefsActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allDietaryPreferences}
            checkedOptions={profile.mustIncludeDietPrefs || []}
            onChange={(newOptions) =>
              setProfile({ ...profile, mustIncludeDietPrefs: newOptions })
            }
          />
          <YSpacing height="7px" />
          <Checkbox
            label="Must Exclude"
            checked={profile.mustExcludeDietPrefsActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustExcludeDietPrefsActive: !profile.mustExcludeDietPrefsActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allDietaryPreferences}
            checkedOptions={profile.mustExcludeDietPrefs || []}
            onChange={(newOptions) =>
              setProfile({ ...profile, mustExcludeDietPrefs: newOptions })
            }
          />
        </FlexContainer>
        <FlexContainer flexDirection="column" width="45%">
          <Label>Cuisine Preferences</Label>
          <YSpacing height="10px" />
          <Checkbox
            label="Must Include"
            checked={profile.mustIncludeCuisinesActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustIncludeCuisinesActive: !profile.mustIncludeCuisinesActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allCuisines}
            checkedOptions={profile.mustIncludeCuisines || []}
            onChange={(newOptions) =>
              setProfile({ ...profile, mustIncludeCuisines: newOptions })
            }
          />
          <YSpacing height="7px" />
          <Checkbox
            label="Must Exclude"
            checked={profile.mustExcludeCuisinesActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustExcludeCuisinesActive: !profile.mustExcludeCuisinesActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allCuisines}
            checkedOptions={profile.mustExcludeCuisines || []}
            onChange={(newOptions) =>
              setProfile({ ...profile, mustExcludeCuisines: newOptions })
            }
          />
        </FlexContainer>
      </FlexContainer>
      <DividerLine margin="20px 0" />
      <FlexContainer width="100%" justifyContent="space-between">
        <Button
          testId="delete-diner-profile"
          label="Delete"
          backgroundColor="#D53C50"
          disabled={!profile.id}
          onClick={onDeleteDinerProfile}
        />
        <FlexContainer width="33%" justifyContent="space-between">
          <Button
            testId="save-diner-profile"
            label="Save"
            backgroundColor="#6580CC"
            onClick={onSaveProfile}
          />
          <Button label="Cancel" onClick={closeModal} />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}

export default EditDinerProfileModal
