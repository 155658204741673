import axios from 'axios'
import { snakeCaseify } from '../../../utils'

export const LoadAccount =
  ({ RestService, pResponseAccount }) =>
  async (accountId) => {
    const account = await RestService.get(`/api/admin/accounts/${accountId}`)

    return pResponseAccount(account)
  }

export const SearchDetailAccounts =
  ({ RestService, pResponseAccounts }) =>
  async (settings) => {
    const accounts = await RestService.get(
      '/api/admin/accounts',
      { ...settings, account_serializer: true },
      { timeout: 60000 },
    )

    return pResponseAccounts(accounts)
  }

export const GetIndexAccountsByIds =
  ({ RestService, pResponseAccounts }) =>
  async (ids) => {
    const accounts = await RestService.get(
      '/api/admin/accounts',
      { ids },
      { timeout: 30000 },
    )

    return pResponseAccounts(accounts)
  }

export const SearchIndexAccounts =
  ({ RestService, pResponseAccounts }) =>
  async ({ search, searchAllHqs = true }) => {
    const params = { search }
    if (searchAllHqs) {
      params.is_vcx = true // is_vcx set to true to not scope by headquarter
    }
    const accounts = await RestService.get('/api/admin/accounts', params, {
      timeout: 30000,
    })

    return pResponseAccounts(accounts)
  }

export const GetDetailAccountsByIds =
  ({ RestService, pResponseAccounts }) =>
  async (ids) => {
    const accounts = await RestService.get('/api/admin/accounts', {
      ids,
      account_serializer: true,
    })

    return pResponseAccounts(accounts)
  }

export const LoadAccountIntel =
  ({ RestService, pResponseGeneric }) =>
  async (accountId) => {
    const account = await RestService.get(`/api/admin/accounts/${accountId}`, {
      serializer: 'Intel',
    })

    return pResponseGeneric(account)
  }

export const LoadAccounts =
  ({ AccountService, RestService, pResponseAccounts }) =>
  async (filters) => {
    const snakeFilters = snakeCaseify(filters)
    let accounts = await RestService.get('/api/admin/accounts', {
      page: 1,
      results_per_page: 50,
      ...snakeFilters,
    })
    accounts = pResponseAccounts(accounts)
    AccountService.setAccounts(accounts)
  }

export const LoadAccountReferralPartners =
  ({ AccountService, RestService, pResponseGeneric }) =>
  async () => {
    let referralPartners = await RestService.get(
      '/api/admin/contacts',
      {
        page: 1,
        results_per_page: 50,
        is_referral_partner: true,
      },
      { timeout: 30000 },
    )
    referralPartners = pResponseGeneric(referralPartners)
    AccountService.setReferralPartners(referralPartners)
    AccountService.setReferredBy()

    return referralPartners || []
  }

export const LoadPreferenceProfile =
  ({ RestService, pResponsePreferenceProfile }) =>
  async (accountId) => {
    const profile = await RestService.get(
      `/api/admin/preference_profiles/${accountId}`,
    )

    return pResponsePreferenceProfile(profile)
  }

export const GetAccountingClient =
  ({ RestService, pResponseEditClientAccounting }) =>
  async (accountId) => {
    const accounting = await RestService.get(`/accounting/client/${accountId}`)

    return pResponseEditClientAccounting(accounting)
  }

export const GetClientOutstandingOrders =
  ({ RestService, pResponseClientOutstandingOrders }) =>
  async ({ clientId, limit, offset, orderDateBefore }) => {
    const orders = await RestService.get(
      `/accounting/accounts-receivable/client-orders`,
      { clientId, limit, offset, orderDateBefore },
    )

    return pResponseClientOutstandingOrders(orders)
  }

// YYYY-MM-DD
export const GetClientsByAutoBatchDate =
  ({ RestService }) =>
  async (dateStr) => {
    const clients = await RestService.get(
      `/accounting/accounts-receivable/clients-by-auto-batch-date`,
      {
        dateStr,
      },
    )

    return clients
  }

export const ValidateClientAutoInvBatchable =
  ({ RestService, HandleError }) =>
  async (clientId) => {
    try {
      const validation = await RestService.get(
        `/accounting/client/${clientId}/validate-client-auto-inv-batchable`,
      )

      return validation
    } catch (error) {
      HandleError({ error })

      return
    }
  }

export const SelectAccount =
  ({
    AccountService,
    RestService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  }) =>
  async (accountId) => {
    let account = await RestService.get(`/api/admin/accounts/${accountId}`)
    const accounting = await RestService.get(`/accounting/client/${accountId}`)
    account = {
      ...pResponseEditAccount(account),
      ...pResponseEditClientAccounting(accounting),
    }
    AccountService.setAccount(account)
  }

export const LoadAccountStats =
  ({ RestService, pResponseAccountStats }) =>
  async (accountId) => {
    const stats = await RestService.get(
      `/api/admin/accounts/${accountId}/account_stats`,
    )

    return pResponseAccountStats(stats)
  }

export const AsyncLoadAccountContacts =
  ({ RestService, pResponseGeneric }) =>
  async (settings) => {
    let accountContacts = await RestService.get('/api/admin/contacts', settings)
    accountContacts = pResponseGeneric(accountContacts)

    return accountContacts || []
  }

export const NewAccount =
  ({ AccountService, UIService }) =>
  () => {
    AccountService.clearAccount()
    UIService.EditAccount.showSearchViaHubSpot()
  }

export const NewManualAccount =
  ({ AccountService, HubspotService, UIService }) =>
  () => {
    AccountService.clearAccount()
    UIService.EditAccount.closeSearchViaHubSpot()
    UIService.EditAccount.show()
    HubspotService.setSingle(null) // clear any previous set hubspot company
  }

export const NewAccountFromHubspot =
  ({
    AccountService,
    HubspotService,
    UIService,
    pResponseCreateAccountFromHubspot,
  }) =>
  (data) => {
    AccountService.clearAccount()
    UIService.EditAccount.closeSearchViaHubSpot()
    const account = pResponseCreateAccountFromHubspot(data)
    AccountService.setEditAccount(account)
    UIService.EditAccount.show()
    const hubspotCompany = {
      hubspotId: data.id,
      name: data.name,
      hungryId: undefined,
      needHubspotSync: true,
    }
    HubspotService.setSingle(hubspotCompany)
  }

export const CreateAccountDocument =
  ({ RestService, HandleError }) =>
  async (imageFile, clientId, docType) => {
    let fileUrl
    try {
      if (imageFile !== undefined) {
        const file = imageFile

        // Get presigned url
        const presignedUrlData = {
          imageType: 'accounting',
          mimeType: file.type,
        }
        const { presignedUrl, publicUrl } = await RestService.get(
          '/images/presigned',
          presignedUrlData,
        )
        fileUrl = publicUrl

        const config = {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
          timeout: 120000,
        }

        // Upload Image to S3
        let s3Response
        try {
          s3Response = await axios.put(presignedUrl, file, config)
        } catch (error) {
          throw new Error(`Upload to S3 failed. ${error}`)
        }

        if (
          s3Response &&
          (s3Response.status === 200 || s3Response.statusText === 'OK')
        ) {
          await RestService.put(`/accounting/client/${clientId}`, {
            id: clientId,
            [docType]: fileUrl,
          })
        }
      }
    } catch (error) {
      HandleError({ error })

      return
    }

    return fileUrl
  }

export const UploadAccountLogoImage =
  ({ RestService, HandleError }) =>
  async (imageFile) => {
    let logoUrl
    try {
      if (imageFile !== undefined) {
        const file = imageFile
        // Get presigned url
        const presignedUrlData = {
          imageType: 'client_logo',
          mimeType: file.type,
        }
        const { presignedUrl, publicUrl } = await RestService.get(
          '/images/presigned',
          presignedUrlData,
        )
        logoUrl = publicUrl

        const config = {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
          timeout: 120000,
        }

        // Upload Image to S3
        let s3Response
        try {
          s3Response = await axios.put(presignedUrl, file, config)
        } catch (error) {
          throw new Error(`Upload to S3 failed. ${error}`)
        }

        if (
          s3Response &&
          (s3Response.status === 200 || s3Response.statusText === 'OK')
        ) {
          return logoUrl
        }
      }
    } catch (error) {
      HandleError({ error })

      return
    }

    return
  }

// Account contacts
export const LoadAccountContacts =
  ({
    AccountService,
    RestService,
    pRequestAccountContacts,
    pResponseAccountContacts,
    HandleError,
  }) =>
  async (accountId) => {
    const req = pRequestAccountContacts(accountId)
    const headers = { 'Content-Type': 'multipart/form-data' }
    try {
      const accountContacts = await RestService.get(
        '/api/admin/account_contacts',
        req,
        headers,
      )
      AccountService.setAccountContacts(
        pResponseAccountContacts(accountContacts),
      )

      return pResponseAccountContacts(accountContacts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const NewAccountContact =
  ({ AccountService, UIService }) =>
  (accountContacts) => {
    const { account, editAccountContact } = AccountService.getState()
    if (editAccountContact.id) {
      AccountService.clearEditAccountContact()
    }

    if (account) {
      AccountService.setEditAccountContact({
        accountId: account.id,
        accountContacts,
      })
      UIService.EditAccountContact.show()
    }
  }

export const EditAccountContact =
  ({ AccountService, UIService }) =>
  (accountContact, accountContacts) => {
    const { account, editAccountContact } = AccountService.getState()
    if (editAccountContact.id) {
      AccountService.clearEditAccountContact()
    }
    if (account) {
      AccountService.setEditAccountContact({
        ...accountContact,
        accountId: account.id,
        accountContacts,
      })
      UIService.EditAccountContact.show()
    }
  }

export const SaveAccountContact =
  ({
    AccountService,
    RestService,
    UIService,
    pRequestUpdateAccountContact,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  }) =>
  async (data) => {
    UIService.Errors.clear()
    const req = pRequestUpdateAccountContact(data)
    const headers = { 'Content-Type': 'multipart/form-data' }
    try {
      if (data.id) {
        await RestService.put(
          `/api/admin/account_contacts/${data.id}`,
          req,
          headers,
        )
      } else {
        await RestService.post('/api/admin/account_contacts', req, headers)
      }
      UIService.FlashMessage.displaySuccessMessage(
        `Successfully ${data.id ? 'updated' : 'created'} Account Contact: ${
          data.email
        }`,
      )
      SelectAccount({
        AccountService,
        RestService,
        pResponseEditAccount,
        pResponseEditClientAccounting,
      })(data.accountId)
      UIService.EditAccountContact.close()
    } catch (error) {
      HandleError({ error, namespace: 'accountContactModal' })
    }
  }

export const DeleteAccountContact =
  ({
    RestService,
    UIService,
    AccountService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  }) =>
  async (contactId, accountId, name) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete contact "${name}"?`,
    })
    try {
      if (doDelete) {
        await RestService.delete(`/api/admin/account_contacts/${contactId}`)
        await SelectAccount({
          AccountService,
          RestService,
          pResponseEditAccount,
          pResponseEditClientAccounting,
        })(accountId)
      }
      UIService.FlashMessage.displaySuccessMessage(
        `Successfully deleted Account Contact: ${name}`,
      )
      UIService.EditAccountContact.close()
    } catch (error) {
      HandleError({ error })
    }
  }

export const DelayedUpdateAccountContact =
  ({ AccountService, UIService, pStateToReduxAccountContact }) =>
  (accountContact) => {
    const call = () =>
      AccountService.updateEditAccountContact(
        pStateToReduxAccountContact(accountContact),
      )
    UIService.Timer.callAfterTimeout(call)
  }

export const LoadAccountCustomers =
  ({ RestService, pResponseGeneric, HandleError }) =>
  async (req) => {
    try {
      const customers = await RestService.get('/users/pop-up-customers', req)

      return pResponseGeneric(customers)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const LoadMktplaceUsers =
  ({ RestService, pResponseGeneric }) =>
  async (settings) => {
    let mktplaceUsers = await RestService.get(
      'api/marketplace/marketplace_users',
      settings,
    )
    mktplaceUsers = pResponseGeneric(mktplaceUsers)

    return mktplaceUsers || []
  }

export const LoadAccountMarketplaceUsers =
  ({ RestService, pResponseGeneric, HandleError }) =>
  async (accountId, serializer = null) => {
    let params = {}
    if (serializer) {
      params = { serializer }
    }

    try {
      const mpUsers = await RestService.get(
        `/api/admin/accounts/${accountId}/mp_users`,
        params,
      )

      return pResponseGeneric(mpUsers)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const MarkDefaultAccountContact =
  ({ RestService, AccountService, pResponseAccountContacts, HandleError }) =>
  async (accountId, contactId) => {
    try {
      let updatedContacts = await RestService.put(
        `/api/admin/account_contacts/${contactId}/mark_default`,
        { account_id: accountId },
      )
      updatedContacts = pResponseAccountContacts(updatedContacts)

      const { account } = AccountService.getState()
      const newContacts = account.accountContacts.slice()
      updatedContacts.forEach((contact) => {
        const index = newContacts.findIndex((c) => c.id === contact.id)
        if (index !== -1) {
          newContacts[index] = contact
        }
      })
      AccountService.setAccount({
        ...account,
        accountContacts: newContacts,
      })
    } catch (error) {
      HandleError({ error })
    }
  }

export const MarkDefaultAccountAddress =
  ({ RestService, AccountService, pResponseAddresses, HandleError }) =>
  async (accountId, addressId) => {
    try {
      let updatedAddresses = await RestService.put(
        `/api/admin/accounts/${accountId}/mark_default_address`,
        { address_id: addressId },
      )
      updatedAddresses = pResponseAddresses(updatedAddresses)

      const { account } = AccountService.getState()
      const newAddresses = account.addresses.slice()
      updatedAddresses.forEach((address) => {
        const index = newAddresses.findIndex((a) => a.id === address.id)
        if (index !== -1) {
          newAddresses[index] = address
        }
      })
      AccountService.setAccount({
        ...account,
        addresses: newAddresses,
      })
    } catch (error) {
      HandleError({ error })
    }
  }

export const RemoveAccountContact =
  ({ RestService, AccountService, UIService, HandleError }) =>
  async ({ id, name, contactId }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete contact "${name}"?`,
    })
    if (!doDelete) {
      return
    }
    try {
      await RestService.delete(`/api/admin/account_contacts/${id}`)
      UIService.FlashMessage.displaySuccessMessage(
        `Successfully deleted Account Contact: ${name}`,
      )

      const { account } = AccountService.getState()
      AccountService.setAccount({
        ...account,
        accountContacts: account.accountContacts.filter((c) => c.id !== id),
        contacts: account.contacts.filter((c) => c.id !== contactId),
      })
    } catch (error) {
      HandleError({ error })
    }
  }

export const RemoveAccountAddress =
  ({ RestService, AccountService, UIService, HandleError }) =>
  async ({ id, line1 }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete address "${line1}"?`,
    })
    if (!doDelete) {
      return
    }
    try {
      await RestService.delete(`/api/admin/addresses/${id}`)
      UIService.FlashMessage.displaySuccessMessage(
        `Successfully deleted Address: ${line1}`,
      )

      const { account } = AccountService.getState()
      AccountService.setAccount({
        ...account,
        addresses: account.addresses.filter((a) => a.id !== id),
      })
    } catch (error) {
      HandleError({ error })
    }
  }
