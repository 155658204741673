import React, { useEffect, useState, useCallback } from 'react'
import Flatpickr from 'react-flatpickr'
import moment, { Moment } from 'moment-timezone'
import { TechHelpForm } from '@components/common'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import { FlexContainer, XSpacing, YSpacing, Loader } from '@components/common'
import { DateInput, Input } from '@components/common/form'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { OrderDetailsModal } from '@components/proposal/proposalSections'
import { colors } from '../../../../constants'
import {
  convertTimeIntoBrowserTz,
  convertTimeIntoMomentTz,
} from '../../../../utils'
import { OrderableConfirmationModal } from '@components/common/modal'
import {
  CateringOrder,
  OrderableConfirmationParamaters,
  Orderable,
} from '@types'
const DateFormat = 'LL'

interface BulkGenProposalsFromOrderProps {
  account: { id: string }
  hqLocaleMap: Record<number, string>
  generateBulkProposals: (
    hqLocaleMap: Record<number, string>,
    param: OrderableConfirmationParamaters[],
    callbackFn: (
      result: Orderable | null,
      index: number,
      error?: string,
    ) => void,
  ) => Orderable
  searchOrders: (val: {
    fromDate?: Date
    toDate?: Date
    orderNumber: string
    id: string
    serializer: string
  }) => CateringOrder[]
  navigateToPreviousSection: () => void
  onHide: () => void
  backToProposalOptions: () => void
  setSkipToCustomMenus: () => void
  uri: string
}

const BulkGenProposalsFromOrder = ({
  account,
  hqLocaleMap,
  generateBulkProposals,
  searchOrders,
  navigateToPreviousSection,
  backToProposalOptions,
  setSkipToCustomMenus,
  onHide,
  uri,
}: BulkGenProposalsFromOrderProps) => {
  const [selectedOrder, setSelectedOrder] = useState<CateringOrder>()
  const [selectedDates, setSelectedDates] = useState<Moment[]>([])
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()
  const [orderNumber, setOrderNumber] = useState('')
  const [orders, setOrders] = useState<CateringOrder[]>([])
  const [orderDetails, setOrderDetails] = useState<CateringOrder>()
  const [isLoading, setIsLoading] = useState(false)
  const [genProposals, setGenProposals] = useState(false)
  const [genParams, setGenParams] = useState<OrderableConfirmationParamaters[]>(
    [],
  )

  const onDateSelection = (selectedDates: Date[]) => {
    const newDates: Moment[] = []
    selectedDates.forEach((d: Date) => {
      const dateInTz = convertTimeIntoMomentTz(d)
      newDates.push(dateInTz)
    })
    setSelectedDates(newDates)
  }

  const onSearchOrders = useCallback(async () => {
    const { id } = account
    setIsLoading(true)
    const accountOrders = await searchOrders({
      fromDate,
      toDate,
      orderNumber,
      id,
      serializer: 'for_orders',
    })
    if (accountOrders) {
      setOrders(accountOrders)
    }
    setIsLoading(false)
  }, [account, fromDate, toDate, orderNumber, searchOrders])

  useEffect(() => {
    const handler = setTimeout(() => {
      onSearchOrders()
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [fromDate, toDate, orderNumber, onSearchOrders])

  const genBulkProposals = async () => {
    if (selectedOrder) {
      const params: OrderableConfirmationParamaters[] = []
      selectedDates.forEach((d) => {
        params.push({
          date: d,
          order: selectedOrder,
        })
      })
      setGenParams(params)
      setGenProposals(true)
    }
  }

  const renderOrders = () => {
    return (
      <div className="h-80 overflow-scroll border rounded">
        {orders.map((o) => (
          <div
            key={o.id}
            style={{
              border: '1px solid rgb(74, 85, 104)',
              borderRadius: '0.375rem',
            }}
            className="bg-white p-4 mb-2 w-5/6 shadow-lg"
          >
            <h4 className="font-bold m-0 mb-2">{o.orderNumber}</h4>
            <FlexContainer flexDirection="row">
              <FlexContainer
                flexDirection="column"
                width="45%"
                justifyContent="space-between"
                height="90px"
              >
                <p>
                  <strong>Date:</strong>{' '}
                  {moment(o.clientSetUpTime).format('dddd, MMMM, Do')}
                </p>
                <p>
                  <strong>Time:</strong>{' '}
                  {moment(o.clientSetUpTime).format('h:mma')}
                </p>
                <p>
                  <strong>Head Count:</strong> {o.headCount}
                </p>
              </FlexContainer>
              <FlexContainer
                flexDirection="column"
                width="45%"
                justifyContent="space-between"
                height="90px"
              >
                <p className="vendor text-sm text-gray-800">
                  <strong>Vendor:</strong>{' '}
                  {o.conceptName ? `${o.conceptName} By ` : ''}{' '}
                  {((o.chefs || [])[0] || {}).name || 'No Chef'}
                </p>
                <p
                  className="link text-sm text-blue-500 underline cursor-pointer"
                  onClick={() => setOrderDetails(o)}
                >
                  View Details
                </p>
              </FlexContainer>
            </FlexContainer>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      {orderDetails && (
        <OrderDetailsModal
          orderDetails={orderDetails}
          hideModal={() => setOrderDetails(undefined)}
          setSelectedOrder={(order: CateringOrder) => setSelectedOrder(order)}
        />
      )}
      {genProposals && (
        <OrderableConfirmationModal
          orderable="Proposal"
          orderableType="proposals"
          params={genParams}
          hqLocaleMap={hqLocaleMap}
          coordinator={generateBulkProposals}
          uri={uri}
          errorLinks={[
            {
              message: '< Go Back to Select Another Past Order to Copy',
              clickHandler: () => setGenProposals(false),
            },
            {
              message: 'Create a Custom Proposal Instead >',
              clickHandler: setSkipToCustomMenus,
            },
          ]}
          successLinks={[
            {
              html: `${uri}/proposals-dashboard`,
              message: 'Go to Proposals Dashboard to View Proposals',
            },
          ]}
          onClose={onHide}
        />
      )}
      <OrderFormSectionTitle>
        <span>3</span>Generate Bulk Proposals
        <XSpacing width="25px" />
      </OrderFormSectionTitle>
      <YSpacing height="15px" />
      <div style={{ marginLeft: '30px' }}>
        <OrderFormSectionTitle>
          <p className="message" onClick={backToProposalOptions}>
            Go Back To Generate Bulk Proposal Options
          </p>
          <XSpacing width="10px" />
          <p className="message" onClick={setSkipToCustomMenus}>
            {'Skip to Create Custom Proposal >'}
          </p>
        </OrderFormSectionTitle>
        <YSpacing height="15px" />
        <h1 className="text-red-500 font-bold underline">
          Search Past Orders to Copy & Bulk Create Proposals
        </h1>
        <YSpacing height="30px" />
        <div className="flex flex-col">
          <h1 className="font-bold text-xl underline">How it Works:</h1>
          <YSpacing height="10px" />
          <ol className="ml-5 list-decimal">
            <li className="text-lg leading-relaxed">
              Select the date(s) to bulk copy the same order with all the same
              order details & menu items added to the selected order
            </li>
            <li className="text-lg leading-relaxed">
              Click Generate Bulk Proposals
            </li>
            <li className="text-lg leading-relaxed">
              You will recieve a confirmation once proposals are created & a
              quick link to direct you to your proposals dashboard to view the
              copied proposals
            </li>
          </ol>
        </div>
        <YSpacing height="30px" />
        <FlexContainer
          alignItems="flex-start"
          justifyContent="space-between"
          width="50%"
        >
          <DateInput
            label="From"
            date={fromDate}
            width="30%"
            onChange={(val: Date) => setFromDate(val)}
            clearDate={() => setFromDate(undefined)}
          />
          <DateInput
            label="To"
            date={toDate}
            width="30%"
            onChange={(val: Date) => setToDate(val)}
            clearDate={() => setToDate(undefined)}
          />
          <Input
            label="Order Number"
            type="text"
            width="30%"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
          />
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flexDirection="column">
            <p>Search Results</p>
            {isLoading ? <Loader /> : renderOrders()}
          </FlexContainer>
          <FlexContainer flexDirection="column">
            {selectedOrder && (
              <>
                <p>Selected</p>
                {orders
                  .filter((o) => o.id === selectedOrder.id)
                  .map((o) => (
                    <div
                      className="bg-green-bright p-4 mb-2 w-5/6 shadow-md"
                      style={{
                        border: '1px solid rgb(74, 85, 104)',
                        borderRadius: '0.375rem',
                      }}
                      key={o.id}
                    >
                      <h4 className="font-bold m-0 mb-2">{o.orderNumber}</h4>
                      <FlexContainer flexDirection="row">
                        <FlexContainer
                          flexDirection="column"
                          width="45%"
                          justifyContent="space-between"
                          height="90px"
                        >
                          <p>
                            <strong>Date:</strong>{' '}
                            {moment(o.clientSetUpTime).format('dddd, MMMM, Do')}
                          </p>
                          <p>
                            <strong>Time:</strong>{' '}
                            {moment(o.clientSetUpTime).format('h:mma')}
                          </p>
                          <p>
                            <strong>Head Count:</strong> {o.headCount}
                          </p>
                        </FlexContainer>
                        <FlexContainer
                          flexDirection="column"
                          width="45%"
                          justifyContent="space-between"
                          height="90px"
                        >
                          <p className="vendor text-sm text-gray-800">
                            <strong>Vendor:</strong>{' '}
                            {o.conceptName ? `${o.conceptName} By ` : ''}{' '}
                            {((o.chefs || [])[0] || {}).name || 'No chef'}
                          </p>
                          <p
                            className="link text-sm text-blue-500 underline cursor-pointer"
                            onClick={() => setOrderDetails(o)}
                          >
                            View Details
                          </p>
                        </FlexContainer>
                      </FlexContainer>
                    </div>
                  ))}
              </>
            )}
            <div className="relative w-3/5" style={{ zIndex: 99 }}>
              <Flatpickr
                height="400px"
                width="800px"
                value={selectedDates.map((d) => convertTimeIntoBrowserTz(d))}
                options={{
                  mode: 'multiple',
                  altInput: true,
                  altInputClass: 'hidden',
                  inline: true,
                  formatDate: (date: Date) => moment(date).format(DateFormat),
                  onDayCreate: (dObj, dStr, fp, dayElem) => {
                    const date = dayElem.dateObj
                    const formattedDate = date.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })

                    // Set a unique test id for each day in the "September 30, 2024" format
                    dayElem.setAttribute('data-testid', `${formattedDate}`)
                  },
                  onReady: (selectedDates, dateStr, instance) => {
                    const nextButton = instance.calendarContainer.querySelector(
                      '.flatpickr-next-month',
                    )
                    const prevButton = instance.calendarContainer.querySelector(
                      '.flatpickr-prev-month',
                    )

                    if (nextButton) {
                      nextButton.setAttribute(
                        'data-testid',
                        'next-month-button',
                      )
                    }
                    if (prevButton) {
                      prevButton.setAttribute(
                        'data-testid',
                        'prev-month-button',
                      )
                    }
                  },
                }}
                onChange={(selectedDates) => onDateSelection(selectedDates)}
              />
            </div>
          </FlexContainer>
        </FlexContainer>
      </div>
      <YSpacing height="40px" />
      <FlexContainer justifyContent="flex-end">
        <Button
          testId="update-order-details"
          label={'〈 Update order details'}
          onClick={navigateToPreviousSection}
        />
        <XSpacing width="15px" />
        <Button
          testId="generate-bulk-proposals"
          label={'Generate Bulk Proposals '}
          onClick={genBulkProposals}
          backgroundColor={colors.orange}
          disabled={selectedDates.length === 0 || !selectedOrder}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <TechHelpForm margin="0 30px 0 0" />
        <LinkText
          testId="cancel"
          label="Cancel"
          onClick={onHide}
          color={colors.violet}
        />
      </FlexContainer>
    </div>
  )
}

export default BulkGenProposalsFromOrder
