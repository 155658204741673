import { connect } from 'react-redux'

import { AccountProfile } from '@components/account/accountSections'

import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account, dinerProfiles, user } = state

  return {
    account,
    currentUser: user,
    dinerProfiles,
  }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService, UIService } = services
  const {
    EditAccount,
    LoadAccountStats,
    LoadAccountDinerProfiles,
    EditDinerProfile,
    DeleteDinerProfile,
    MarkDefaultDinerProfile,
    MarkDefaultAccountContact,
    MarkDefaultAccountAddress,
    RemoveAccountContact,
    RemoveAccountAddress,
  } = coordinators
  const {
    pResponseEditAccount,
    pResponseEditClientAccounting,
    pResponseAccountStats,
    pResponseAccountContacts,
    pResponseGeneric,
    pResponseDinerProfiles,
  } = presenters.Api

  const editAccount = EditAccount({
    AccountService,
    RestService,
    UIService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  })
  const loadAccountStats = LoadAccountStats({
    RestService,
    pResponseAccountStats,
  })
  const loadDinerProfiles = LoadAccountDinerProfiles({
    RestService,
    AccountService,
    HandleError,
    pResponseDinerProfiles,
  })
  const editDinerProfile = EditDinerProfile({
    AccountService,
    UIService,
  })
  const deleteDinerProfile = DeleteDinerProfile({
    RestService,
    AccountService,
    UIService,
    HandleError,
  })
  const markDefaultDinerProfile = MarkDefaultDinerProfile({
    RestService,
    AccountService,
    HandleError,
    pResponseDinerProfiles,
  })
  const deleteAccountContact = RemoveAccountContact({
    RestService,
    AccountService,
    UIService,
    HandleError,
  })
  const markDefaultAccountContact = MarkDefaultAccountContact({
    RestService,
    AccountService,
    pResponseAccountContacts,
    HandleError,
  })
  const deleteAccountAddress = RemoveAccountAddress({
    RestService,
    AccountService,
    HandleError,
  })
  const markDefaultAccountAddress = MarkDefaultAccountAddress({
    RestService,
    AccountService,
    pResponseAddresses: pResponseGeneric,
    HandleError,
  })

  return {
    editAccount,
    loadAccountStats,
    loadDinerProfiles,
    editDinerProfile,
    deleteDinerProfile,
    markDefaultDinerProfile,
    markDefaultAccountContact,
    markDefaultAccountAddress,
    deleteAccountContact,
    deleteAccountAddress,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile)
