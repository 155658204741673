import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TechHelpForm } from '@components/common'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import { FlexContainer, XSpacing, YSpacing } from '@components/common'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { colors, MENU_DETAILS_SECTION_MODE_VIEW } from '../../../../constants'
import { BulkGenerateOrderable } from '@components/proposal/proposalSections'
import {
  BulkGenProposalsFromOrder,
  BulkGenProposalsFromDinerProfile,
} from '@containers/proposal/proposalSections'
import { OrderablePropType } from './propTypes'

const EditOrderSection2 = (props) => {
  const {
    nextSection,
    orderable,
    previousSection,

    onHide,
    updateSkippedState,
    navigateToSection,
  } = props

  const { account } = orderable
  const [generateBulkProposalsFromOrder, setGenerateBulkProposalsFromOrder] =
    useState(false)
  const [
    generateBulkProposalsFromDinerProfile,
    setGenerateBulkProposalsFromDinerProfile,
  ] = useState(false)

  const navigateToPreviousSection = () => {
    navigateToSection(previousSection)
  }

  const navigateToNextSection = () => {
    navigateToSection(nextSection)
  }

  const handleSkip = () => {
    updateSkippedState(true)
    navigateToNextSection()
  }

  if (
    !generateBulkProposalsFromOrder &&
    !generateBulkProposalsFromDinerProfile
  ) {
    return (
      <>
        <OrderFormSectionTitle>
          <span>3</span>Generate Bulk Proposals
          <XSpacing width="25px" />
          <p className="message" onClick={handleSkip}>
            {'Skip to Create Custom Proposal >'}
          </p>
        </OrderFormSectionTitle>
        <YSpacing height="15px" />
        <BulkGenerateOrderable
          title="Search Past Orders & Generate Bulk Proposals with Menu Items"
          message="Have Consistent orders you'd like to repeat for the day(s) each week such as a weekly breakfast from the same vendor? Click here to search a past order and you can bulk create the same order for future Dates"
          example="Good for: Weekly breakfast orders every Money with the same anchor vendor that provides bagels"
          buttonLabel="Bulk Copy a Past Order"
          buttonAction={() => setGenerateBulkProposalsFromOrder(true)}
        />
        <YSpacing height="30px" />
        <BulkGenerateOrderable
          title="Generate Bulk Proposals without Menu Items"
          message="Have a weekly food program such as weekly lunches on the same day(s) of the week but need to switch up the vendors or menus? Click here to bulk generate proposals without menu items but keeping all other order details"
          example="Good for: Weekly lunch orders every Monday, Wednesday, and Thursday but client requires a different vendor or menu each day"
          buttonDisabled={!orderable.dinerProfileId}
          buttonDisableMessage={'Need to have a Diner Profile Selected.'}
          buttonLabel="Generate Bulk Empty Proposals"
          buttonAction={() => setGenerateBulkProposalsFromDinerProfile(true)}
        />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <Button
            testId="update-order-details"
            label={'〈 Update order details'}
            onClick={navigateToPreviousSection}
          />
          <XSpacing width="20px" />
          <Button
            testId="next-section"
            label={'Skip to Create Custom Proposal 〉'}
            onClick={handleSkip}
            backgroundColor={colors.orange}
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <TechHelpForm margin="0 30px 0 0" />
          <LinkText
            testId="cancel"
            label="Cancel"
            onClick={onHide}
            color={colors.violet}
          />
        </FlexContainer>
      </>
    )
  } else if (generateBulkProposalsFromDinerProfile) {
    return (
      <BulkGenProposalsFromDinerProfile
        dinerProfile={orderable.dinerProfile}
        backToProposalOptions={() =>
          setGenerateBulkProposalsFromDinerProfile(false)
        }
        navigateToPreviousSection={navigateToPreviousSection}
        onHide={onHide}
        setSkipToCustomMenus={handleSkip}
      />
    )
  } else {
    return (
      <BulkGenProposalsFromOrder
        account={account}
        backToProposalOptions={() => setGenerateBulkProposalsFromOrder(false)}
        navigateToPreviousSection={navigateToPreviousSection}
        onHide={onHide}
        setSkipToCustomMenus={handleSkip}
      />
    )
  }
}

EditOrderSection2.propTypes = {
  orderable: OrderablePropType,
  previousSection: PropTypes.string,
  nextSection: PropTypes.string,

  onHide: PropTypes.func,
  navigateToSection: PropTypes.func,
  updateSkippedState: PropTypes.func,
}

export default EditOrderSection2
